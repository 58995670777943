import ServicesIcon from '../images/servicesIcon.svg'
import ProjectsIcon from '../images/projectsIcon.svg'
import ContactIcon from '../images/contactIcon.svg'

export default [
  {
    text: 'Services',
    scrollToId: '#services',
    iconComponent: ServicesIcon,
  },
  {
    text: 'Projects',
    scrollToId: '#projects',
    iconComponent: ProjectsIcon,
  },
  {
    text: 'Contact',
    scrollToId: '#contact',
    iconComponent: ContactIcon,
  },
]
