import React from 'react'
import styled from 'styled-components'
import servicesData from './servicesData'
import SectionTitle from '../SectionTitle'
import ServicesItem from './ServicesItem'
import ContentContainer from '../UI/ContentContainer'
import VisibilitySensor from '../VisibilitySensor'
import FadeInAnimation from '../UI/FadeInAnimation'

const Services: React.FC = () => {
  return (
    <Wrapper id="services">
      <ContentContainer>
        <SectionTitle text="Services" />
        <ServicesContent>
          {servicesData.map((item) => (
            <VisibilitySensor key={item.title} once>
              {({ isVisible }) => (
                <FadeInAnimation isVisible={isVisible}>
                  <ServicesItem {...item} />
                </FadeInAnimation>
              )}
            </VisibilitySensor>
          ))}
        </ServicesContent>
      </ContentContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 10px;
  padding-top: 50px;
  padding-bottom: 100px;

  @media (min-width: ${({ theme }) => theme.rwd.tablet.s}) {
    margin-top: 20px;
  }

  @media (min-width: ${({ theme }) => theme.rwd.desktop.s}) {
    margin-top: 50px;
  }
`

const ServicesContent = styled.div`
  @media (min-width: ${({ theme }) => theme.rwd.tablet.s}) {
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;
  }

  & > div {
    padding-top: 40px;
    padding-left: 10px;

    @media (min-width: 480px) and (max-width: 767px) {
      display: flex;
    }

    @media (min-width: ${({ theme }) => theme.rwd.tablet.s}) {
      padding-left: 0;
      width: 50%;
    }

    @media (min-width: ${({ theme }) => theme.rwd.desktop.s}) {
      padding-top: 50px;
    }
  }
`

export default Services
