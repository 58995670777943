import React from 'react'
import styled from 'styled-components'

import SectionTitle from '../SectionTitle'
import ContentContainer from '../UI/ContentContainer'
import MapImage from './MapImage'

export const MapSection: React.FC = () => {
  return (
    // <Background>
    <ContentContainer>
      <SectionTitle text="Our customers" />
      <Map>
        <MapImage />
      </Map>
    </ContentContainer>
    // </Background>
  )
}

// const Background = styled.div`
//   padding-top: 25px;
//   height: auto;
//   background: #fafafa;
//
//   @media (max-width: ${({ theme }) => theme.rwd.mobile.maxWidth}) {
//     margin-top: 20px;
//     padding-top: 1px;
//   }
// `

const Map = styled.div`
  text-align: center;

  @media (max-width: ${({ theme }) => theme.rwd.mobile.maxWidth}) {
    margin-top: 20px;
  }

  @media (min-width: ${({ theme }) => theme.rwd.tablet.s}) {
    margin-top: 30px;
  }

  @media (min-width: ${({ theme }) => theme.rwd.desktop.m}) {
    margin-top: 40px;
  }

  img {
    height: 100%;
    width: 100%;
  }
`
