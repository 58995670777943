import React from 'react'
import styled from 'styled-components'
import Seo from '../components/Seo'
import Layout from '../components/layout'
import Hero from '../components/Hero'
import Services from '../components/Services/Services'
import Technologies from '../components/Technologies/Technologies'
import { ProjectsSection } from '../components/Projects/ProjectsSection'
import { MapSection } from '../components/Map/MapSection'
import Footer from '../components/Footer'
import MobileMenu from '../components/MobileMenu'

const IndexPage: React.FC = () => (
  <>
    <Seo />

    <Layout>
      <MobileMenu />

      <Hero />
      <Services />
      <Technologies />
      <ProjectsSection />
      <Background>
        <MapSection />
        <Footer />
      </Background>
    </Layout>
  </>
)
const Background = styled.div`
  padding-top: 25px;
  height: auto;
  background: #fafafa;
  z-index: -10;
  position: absolute;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.rwd.mobile.maxWidth}) {
    margin-top: 20px;
    padding-top: 1px;
  }
`
export default IndexPage
