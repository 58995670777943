import React from 'react'
import styled from 'styled-components'

type ServicesItemProps = {
  icon: string
  text: string[]
  title: string
}

const ServicesItem: React.FC<ServicesItemProps> = ({
  icon: IconComponent,
  text,
  title,
}) => (
  <>
    <Icon>
      <IconComponent />
    </Icon>
    <div>
      <Title>{title}</Title>
      <TextList>
        {text.map((line) => (
          <ListItem key={line}>{line}</ListItem>
        ))}
      </TextList>
    </div>
  </>
)

const Icon = styled.div`
  width: 62px;

  @media (min-width: 480px) and (max-width: 767px) {
    margin-right: 30px;
    padding-top: 10px;
  }

  @media (min-width: 600px) and (max-width: 767px) {
    margin-right: 40px;
  }

  @media (min-width: ${({ theme }) => theme.rwd.tablet.s}) {
    width: 82px;
  }
`

const Title = styled.h1`
  padding-top: 10px;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.s22};

  @media (min-width: ${({ theme }) => theme.rwd.tablet.s}) {
    font-size: ${({ theme }) => theme.fontSize.s25};
    max-width: 85%;
  }

  @media (min-width: ${({ theme }) => theme.rwd.tablet.m}) {
    max-width: 80%;
  }

  @media (min-width: ${({ theme }) => theme.rwd.desktop.s}) {
    font-size: ${({ theme }) => theme.fontSize.smallTitle};
    padding-top: 20px;
  }

  @media (min-width: ${({ theme }) => theme.rwd.desktop.m}) {
    font-size: ${({ theme }) => theme.fontSize.smallTitle};
    padding-top: 20px;
    max-width: 465px;
  }
`

const TextList = styled.ul`
  font-size: ${({ theme }) => theme.fontSize.semiText};
  line-height: 35px;

  @media (min-width: ${({ theme }) => theme.rwd.tablet.s}) {
    font-size: ${({ theme }) => theme.fontSize.semiText};
  }

  @media (min-width: ${({ theme }) => theme.rwd.desktop.s}) {
    font-size: ${({ theme }) => theme.fontSize.text};
    padding-top: 5px;
  }
`

const ListItem = styled.li`
  :before {
    display: inline-block;
    content: '-';
    width: 0.7em;
    margin-left: -0.5em;
  }
`

export default ServicesItem
