import DesignIcon from '../../images/design.svg'
import DeploymentIcon from '../../images/deployment.svg'
import TestingIcon from '../../images/testing.svg'
import ProjectIcon from '../../images/project.svg'

type ServicesDataProps = {
  icon: string
  title: string
  text: string[]
}[]

const servicesData: ServicesDataProps = [
  {
    icon: DesignIcon,
    title: 'Design and Implementation',
    text: [
      'Architecture, Design, MVP',
      'Web & Mobile Technologies',
      'Enterprise backend technologies',
    ],
  },
  {
    icon: DeploymentIcon,
    title: 'Deployment & Maintenance',
    text: [
      'Infrastructure Setup and Maintenance',
      'Containerization/Docker',
      'Cloud Deployment',
      'Mobile Stores Deployment',
    ],
  },
  {
    icon: TestingIcon,
    title: 'Testing and Evaluation',
    text: [
      'End-to-End',
      'Stress Performance Testing',
      'Security Proofing',
    ],
  },
  {
    icon: ProjectIcon,
    title: 'Project Management',
    text: [
      'Project Planning',
      'Estimation & Valuation',
      'Lean & Agile',
    ],
  },
]

export default servicesData
