import React from 'react'
import styled from 'styled-components'

type Props = {
  text: string
}

const SectionTitle: React.FC<Props> = ({ text }: Props) => (
  <Title>{text}</Title>
)

const Title = styled.h3`
  position: relative;
  padding-left: 40px;
  font-size: ${({ theme }) => theme.fontSize.smallTitle};
  margin-bottom: 10px;

  :before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    content: '';
    width: 28px;
    height: 4px;
    background: #0d1957;
    background: linear-gradient(90deg, #0d1957 45%, #6b3ffc 100%);
  }
  @media (min-width: ${({ theme }) => theme.rwd.tablet.s}) {
    padding-left: 50px;
    font-size: ${({ theme }) => theme.fontSize.semiTitle};

    :before {
      width: 36px;
    }
  }

  @media (min-width: ${({ theme }) => theme.rwd.desktop.m}) {
    padding-left: 60px;
    font-size: ${({ theme }) => theme.fontSize.title};
    width: auto;
  }
`

export default SectionTitle
