export default {
  colors: {
    white: '#FFFFFF',
    lightGray: '#FAFAFA',
    gray: '#6F6764',
    text: '#212121',
    orange: '#FF8C56',
    lightViolet: '#832685',
    darkViolet: '#65388E',
    darkBlue: '#010B3F',
    lightBlue: '#6B3FFC',
  },
  fonts: {
    light: 'MontserratLight',
    regular: 'MontserratRegular',
    medium: 'MontserratMedium',
    semiBold: 'MontserratSemiBold',
    bold: 'MontserratBold',
  },
  fontSize: {
    bigTitle: '56px',
    title: '50px',
    semiTitle: '40px',
    smallTitle: '32px',
    s25: '25px',
    s22: '22px',
    s20: '20px',
    bigText: '18px',
    text: '16px',
    semiText: '14px',
    smallText: '12px',
  },
  rwd: {
    mobile: {
      xs: '380px',
      s: '480px',
      m: '600px',
      maxWidth: '767px',
    },
    tablet: {
      s: '768px',
      m: '900px',
      maxWidth: '1279px',
    },
    desktop: {
      s: '1024px',
      m: '1280px',
      l: '1440px',
      xl: '1600px',
    },
  },
}
