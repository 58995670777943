import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import ImageLayer from '../UI/ImageLayer'
import CircleStackIcon from '../../images/circleStack.svg'
import ArrowRightIcon from '../../images/ArrowRightIcon.svg'
import HeroBackground from '../../assets/heroBackground.svg'
import HeroBackgroundMobile from '../../assets/heroBackgroundMin.svg'
import ContentContainer, {
  Wrapper as ContentWrapper,
} from '../UI/ContentContainer'
import { useWindowSize } from '../../hooks'
import Navigation from '../Navigation'
import scrollTo from 'gatsby-plugin-smoothscroll'

const Hero: React.FC = () => {
  const { width } = useWindowSize()

  const data = useStaticQuery(graphql`
    {
      allContentfulDescription {
        edges {
          node {
            title
            shortTitle
          }
        }
      }
    }
  `)

  const { title, shortTitle } = data.allContentfulDescription.edges[0].node

  const description = width < 475 ? shortTitle : title

  return (
    <Wrapper id="hero">
      <HeroImagesContainerSmall>
        <Stage />
        <CircleStackIcon />
      </HeroImagesContainerSmall>

      <Background>
        <ImageLayer src={HeroBackground} />
        <ImageLayer src={HeroBackgroundMobile} isMobile />
      </Background>

      <ContentContainer>
        <Navigation />

        <HeroContainer>
          <HeroContentContainer>
            <Title>
              You imagine IT. <br />
              We create IT.
            </Title>

            <SubTitle>{description}</SubTitle>

            <Button>
              <ButtonText onClick={() => scrollTo('#contact')}>
                Create with us
              </ButtonText>
              <ArrowRightIcon />
            </Button>
          </HeroContentContainer>
        </HeroContainer>
      </ContentContainer>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  width: 100%;
  position: relative;

  ${ContentWrapper} {
    padding-top: 30px;
    padding-bottom: 105px;

    @media (min-width: ${({ theme }) => theme.rwd.tablet.s}) {
      padding-bottom: 150px;
    }
  }
`

const HeroContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 40px;

  @media (min-width: ${({ theme }) => theme.rwd.tablet.s}) {
    padding-top: 70px;
  }

  @media (min-width: ${({ theme }) => theme.rwd.desktop.s}) {
    padding-top: 120px;
  }
`

const HeroContentContainer = styled.div``

const Stage = styled.div`
  font-size: 30px;
  text-align: center;
  width: 100%;
  height: 50;
  position: absolute;
  top: 50%;
  margin-top: -5%;

  :after {
    font-family: ${({ theme }) => theme.fonts.light};
    color: ${({ theme }) => theme.colors.lightGray};
    font-size: ${({ theme }) => theme.fontSize.s25};
    animation: stage 20s ease-in-out infinite;
    content: '';
  }

  @keyframes stage {
    14.3% {
      content: '';
      opacity: 0;
    }
    21.45% {
      opacity: 1;
      content: 'Plan';
    }
    28.6% {
      opacity: 0;
    }
    35.75% {
      opacity: 1;
      content: 'Design';
    }
    42.9% {
      opacity: 0;
    }
    50.05% {
      opacity: 1;
      content: 'Develop';
    }
    57.2% {
      opacity: 0;
    }
    64.35% {
      opacity: 1;
      content: 'Test';
    }
    71.5% {
      opacity: 0;
    }
    78.65% {
      opacity: 1;
      content: 'Release';
    }
    85.8% {
      opacity: 0;
    }
    92.95% {
      opacity: 1;
      content: 'Feedback';
    }
    100% {
      opacity: 0;
    }
  }
`

const HeroImagesContainerSmall = styled.div`
  position: absolute;
  top: 40px;
  right: 3%;
  display: none;

  #circle-icon {
    animation: spin 20s ease-in-out infinite;
    animation-fill-mode: forwards;
    position: relative;
    transform-origin: 49.3% 54.3%;
  }

  @media (min-width: ${({ theme }) => theme.rwd.tablet.m}) {
    display: block;
  }

  @media (min-width: ${({ theme }) => theme.rwd.tablet.m}) {
    top: 90px;
  }

  @media (min-width: ${({ theme }) => theme.rwd.desktop.s}) {
    top: 150px;
    right: 6%;
  }

  @media (min-width: ${({ theme }) => theme.rwd.desktop.m}) {
    top: 180px;
    right: 20%;
  }

  @keyframes spin {
    7.15% {
      transform: rotate(0deg);
    }
    21.45% {
      transform: rotate(360deg);
    }
    35.75% {
      transform: rotate(720deg);
    }
    50.05% {
      transform: rotate(1080deg);
    }
    64.35% {
      transform: rotate(1440deg);
    }
    78.65% {
      transform: rotate(1800deg);
    }
    92.95% {
      transform: rotate(2160deg);
    }
    100% {
      transform: rotate(2160deg);
    }
  }
`

const Background = styled.div`
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
`

const Title = styled.h1`
  max-width: 456px;
  padding-bottom: 30px;
  color: ${({ theme }) => theme.colors.lightGray};
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.smallTitle};

  @media (min-width: ${({ theme }) => theme.rwd.tablet.s}) {
    padding-bottom: 35px;
    font-size: ${({ theme }) => theme.fontSize.title};
  }

  @media (min-width: ${({ theme }) => theme.rwd.desktop.s}) {
    white-space: nowrap;
    padding-bottom: 40px;
    font-size: ${({ theme }) => theme.fontSize.bigTitle};
  }
`

const SubTitle = styled.h3`
  line-height: 35px;
  max-width: 620px;
  color: ${({ theme }) => theme.colors.lightGray};
  font-size: ${({ theme }) => theme.fontSize.bigText};
  font-family: ${({ theme }) => theme.fonts.light};
`

const Button = styled.button`
  display: flex;
  align-items: center;
  border-radius: 40px;
  padding: 15px 20px;
  margin-top: 30px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.5);
  background-color: ${({ theme }) => theme.colors.lightGray};
  transition: box-shadow 0.2s ease-in-out;

  :hover {
    box-shadow: none;

    svg {
      // https://codepen.io/sosuke/pen/Pjoqqp
      filter: invert(70%) sepia(15%) saturate(2895%) hue-rotate(322deg)
        brightness(102%) contrast(101%);
    }
  }
`

const ButtonText = styled.p`
  padding-right: 8px;
  font-size: ${({ theme }) => theme.fontSize.text};
  font-family: ${({ theme }) => theme.fonts.semiBold};

  @media (min-width: ${({ theme }) => theme.rwd.tablet.s}) {
    font-size: ${({ theme }) => theme.fontSize.bigText};
  }
`

export default Hero
