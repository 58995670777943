import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const MapImage: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      mapImage: file(relativePath: { eq: "worldImage.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  if (!data?.mapImage?.childImageSharp?.fluid) {
    return null
  }
  return <Img fluid={data.mapImage.childImageSharp.fluid} />
}

export default MapImage
