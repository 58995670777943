const servicesData = {
  text: "Let's create something ",
  words: [
    'Big',
    'Special',
    'Amazing',
    'Brilliant',
    'Great',
    'Unique',
    'Cool',
  ],
}

export default servicesData
