import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

type Props = {
  title?: string
  description?: string
  keywords?: string[]
}

const Seo = ({ description, title, keywords }: Props): JSX.Element => {
  const { site, allFile } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
            siteUrl
          }
        }
        allFile(filter: { name: { eq: "ogImage" } }) {
          edges {
            node {
              publicURL
            }
          }
        }
      }
    `
  )
  
  const image = `${site.siteMetadata.siteUrl}${allFile.edges[0]?.node.publicURL}`
  const metaDescription = description || site.siteMetadata.description
  const metaTitle = title || site.siteMetadata.title
  const metaKeywords = keywords || site.siteMetadata.keywords

  return (
    <Helmet
      title={metaTitle}
      htmlAttributes={{ lang: 'en' }}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: metaKeywords.join(','),
        },
        {
          property: 'og:image',
          content: image,
        },
        {
          property: 'og:image:width',
          content: '960',
        },
        {
          property: 'og:image:height',
          content: '365',
        },
      ]}
    />
  )
}

export default Seo
